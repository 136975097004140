import styled from 'styled-components'

const Paragraph = styled.p`
  width: 100;
  display: block;
  margin-bottom: 0;
  margin-top: 2rem;

  @media only screen and (min-width: 576px) {
    width: 50%;
  }
`

export default Paragraph
