import React from 'react'
import Layout from '../components/layout'
import Paragraph from '../styledComponents/Paragraph'

const About = () => (
  <Layout>
    <Paragraph>
      They who dream by day are cognizant of many things which escape those who
      dream only by night.
    </Paragraph>
    <small> - Edgar Allan Poe</small>
    <Paragraph>
      Truth is not always in a well. In fact, as regards the more important
      knowledge, I do believe that she is invariably superficial. The depth lies
      in the valleys where we seek her, and not upon the mountain-tops where she
      is found.
    </Paragraph>
    <small> - Edgar Allan Poe</small>
    <Paragraph>
      Monsters are real. Ghosts are too. They live inside of us, and sometimes,
      they win.
    </Paragraph>
    <small> - Stephen King</small>
    <Paragraph>
      There is a property in the horizon which no man has but he whose eye can
      integrate all the parts, that is, the poet.
    </Paragraph>
    <small> - Emerson</small>
    <br></br>
    <Paragraph>
      When the search for truth is confused with political advocacy, the pursuit
      of knowledge is reduced to a quest for power.
    </Paragraph>
    <small> - Alston Chase</small>
  </Layout>
)

export default About;
